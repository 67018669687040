import React from 'react';
import './App.css';
import BusinessCard from './components/BusinessCard.tsx'
import {
  BrowserRouter as Router,
  Route,
  Routes,
 } from "react-router-dom";
 import {Amplify} from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

function App() {
  return (
    <Router>
    <Routes> 
        <Route path='/BusinessCard/:userId' element={<BusinessCard/> }>
        </Route>
    </Routes>
  </Router>
  );
}

export default App;