import React, { useEffect, useState } from "react";
import { Link, BrowserRouter as Router, useParams } from "react-router-dom";
import { API, Amplify, Storage } from "aws-amplify";
import * as queries from "../graphql/queries";
import awsconfig from "../aws-exports";
import "./Style.css";
import { Button, Divider, Flex } from "antd";
import { GoLinkExternal } from "react-icons/go";

import {
  FaTiktok,
  FaYoutube,
  FaPinterestP,
  FaLinkedinIn,
} from "react-icons/fa";
import {
  FaXTwitter,
  FaFacebookF,
  FaWhatsapp,
  FaInstagram,
} from "react-icons/fa6";
import {
  MailOutlined,
  PhoneOutlined,
  GlobalOutlined,
  WhatsAppOutlined,
  InstagramOutlined,
  FacebookOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";
Amplify.configure(awsconfig);

export interface BusinessModelItem {
  name: string;
  company_name: string;
  email: string;
  phone: string;
  website: string;
  address: string;
  whatsapp: string;
  linkedin: string;
  twitter: string;
  youtube: string;
  facebook: string;
  instagram: string;
  pinterest: string;
  tiktok: string;
}
const generateLogoUrl = (userId) => {
  return `https://ezbizzy-filestorage12345836-staging.s3.amazonaws.com/public/UserProfilePictures/${userId}/profile`;
};
const BusinessCard: React.FC = () => {
  const userId = useParams();
  const [nuserID, setUserID] = useState(userId);
  console.log("user", userId);
  const [business, setBusiness] = useState<BusinessModelItem[]>([]);
  const [dataFetched, setDataFetched] = useState(false);

  const [logoUrl, setLogoUrl] = useState<string | undefined>();

  const downloadTxtFile = (vcfText) => {
    const element = document.createElement("a");
    // Set the MIME type to 'text/vcard' instead of 'text/plain;charset=utf-8'
    const file = new Blob([vcfText], { type: "text/vcard" });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.vcf"; // Ensure the file has a .vcf extension
    document.body.appendChild(element);
    element.click();
  };
  useEffect(() => {
    let isMounted = true;
    const logo1 =
      "https://ezbizzy-filestorage12345836-staging.s3.amazonaws.com/public/UserProfilePictures/09d4e34b-f860-4066-884b-7e4b17fe2e53/profile";

    const fetchBusiness = async () => {
      try {
        const BusinessData = await API.graphql({
          query: queries.listBusinessCardModels,
          variables: {
            filter: {
              userID: {
                eq: nuserID.userId,
              },
            },
            limit: 1000,
          },
        });

        if (isMounted) {
          const BusinessResults =
            BusinessData.data.listBusinessCardModels.items;
          setBusiness(BusinessResults);
          console.log(
            "business....",
            BusinessData.data.listBusinessCardModels.items
          );
          // Set the logo URL using the generated function
          setLogoUrl(generateLogoUrl(nuserID.userId));
          console.log("website", BusinessResults.website);
          setDataFetched(true);
        }
      } catch (error) {
        console.error("Error fetching Business:", error);
        // Handle the error or set an error state if necessary
      }
    };

    fetchBusiness();

    return () => {
      isMounted = false;
    };
  }, [nuserID.userId]);

  const handlePhonePress = () => {
    window.location.href = `tel:${business[0].phone}`;
    console.log("calling", business[0].phone);
  };

  const handleMailPress = () => {
    console.log("default mail to", business[0].email);
    window.location.href = `mailto:${business[0].email}`;
  };

  const handleWebPress = () => {
    // Check if the website URL already contains 'http://' or 'https://'
    const websiteUrl =
      business[0].website.startsWith("http://") ||
      business[0].website.startsWith("https://")
        ? business[0].website
        : `https://${business[0].website}`;

    window.location.href = websiteUrl;
    console.log("going to website:", websiteUrl);
  };

  const handleAddressPress = () => {
    window.location.href = `https://www.google.com/maps/search/?api=1&query=${business[0].address}`;
    console.log("navigating to:", business[0].address);
  };
  //VCard Download

  const CreateVCard = () => {
    var vCardsJS = require("vcards-js");
    // Create a new vCard
    var vCard = vCardsJS();

    // Set properties
    const businessInfo = business[0];
    vCard.firstName = businessInfo.name;
    vCard.organization = businessInfo.company_name;
    vCard.cellPhone = businessInfo.phone;

    vCard.workAddress.street = businessInfo.address;
    vCard.title = "EzBizzy";
    vCard.workEmail = businessInfo.email;
    vCard.url = businessInfo.website;
    vCard.note = "Imported From Ezbizzy";

    // Set social URLs
    if (businessInfo.facebook)
      vCard.socialUrls["facebook"] = businessInfo.facebook;
    if (businessInfo.linkedin)
      vCard.socialUrls["linkedin"] = businessInfo.linkedin;
    if (businessInfo.twitter)
      vCard.socialUrls["twitter"] = businessInfo.twitter;
    if (businessInfo.instagram)
      vCard.socialUrls["instagram"] = businessInfo.instagram;
    if (businessInfo.youtube)
      vCard.socialUrls["youtube"] = businessInfo.youtube;
    if (businessInfo.pinterest)
      vCard.socialUrls["pinterest"] = businessInfo.pinterest;
    if (businessInfo.tiktok) vCard.socialUrls["tiktok"] = businessInfo.tiktok;
    if (businessInfo.whatsapp)
      vCard.socialUrls["whatsapp"] = businessInfo.whatsapp;

    // Generate the vCard formatted string
    let vCardString = vCard.getFormattedString();

    // Define a pattern that matches CHARSET=UTF-8; in the context of social profiles
    const charsetPattern = /;CHARSET=UTF-8;/g;

    // Replace CHARSET=UTF-8; globally in the vCard string
    vCardString = vCardString.replace(charsetPattern, ";");

    // Save to file or return the modified string
    return vCardString;
  };

  const ensureHttpPrefix = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };
  const createWhatsAppLink = (phoneNumber) => {
    // Ensure the phone number is in the correct format (remove any characters except digits)
    const formattedNumber = phoneNumber.replace(/\D/g, "");

    // Return the WhatsApp link
    return `https://wa.me/+1${formattedNumber}`;
  };

  return (
    <div className="container">
      {dataFetched ? (
        <div className="box">
          <div
            className="BusinessSec"
            style={{
              boxShadow: "0 15px 20px rgba(128, 128, 128, 0.5)", // Darker shadow with increased opacity
              backgroundColor: "#325FD5", // Blue background color
              color: "white",
              padding: "10px 0", // Padding for visual spacing
            }}
          >
            <div
              style={{
                borderRadius: "50%", // Makes the div circular
                overflow: "hidden", // Ensures the image doesn't spill outside the circle
                width: "100px", // The width of the circular container
                height: "100px", // The height of the circular container, should be the same as width for a perfect circle
                display: "flex", // Enables centering of the image
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
              }}
            >
              <img
                src={logoUrl}
                alt="image"
                className="image"
                style={{
                  border: "5px solid #fafafa",
                  boxShadow:
                    "0 2px 5px rgba(0, 0, 0, 0.5), 0 0 7px rgba(255, 255, 255, 0.6)",
                  maxWidth: "100%", // Ensures image doesn't exceed the div size
                  maxHeight: "100%", // Ensures image doesn't exceed the div size
                  objectFit: "cover", // Ensures the image covers the div area without distortion
                }}
              />
            </div>
            <span style={{ fontSize: "27px", fontWeight: "600" }}>
              {business[0].name}
            </span>
            <span>{business[0].company_name}</span>
            <br />
            <div className="sites" style={{ marginTop: "3px" }}>
              <Flex gap="small" wrap="wrap">
                <Button
                  style={{ backgroundColor: "#325FD5", color: "white" }}
                  shape="circle"
                  size={"large"}
                  onClick={() => handleMailPress()}
                >
                  <MailOutlined />
                </Button>
                <Button
                  style={{ backgroundColor: "#325FD5", color: "white" }}
                  shape="circle"
                  size={"large"}
                  onClick={() => handlePhonePress()}
                >
                  <PhoneOutlined />
                </Button>
                <Button
                  style={{ backgroundColor: "#325FD5", color: "white" }}
                  shape="circle"
                  size={"large"}
                  onClick={() => handleWebPress()}
                >
                  <GlobalOutlined />
                </Button>
              </Flex>
            </div>
          </div>
          <div className="BusinessDetailSec">
            <div className="detailItem">
              <div className="detailTitle">Phone No.</div>
              <div
                className="detailContent"
                style={{ color: "#3141A7", fontWeight: "700" }}
              >
                {business[0].phone}
              </div>
            </div>
            <div className="detailItem">
              <div className="detailTitle">Email</div>
              <div
                className="detailContent"
                style={{ color: "#3141A7", fontWeight: "700" }}
              >
                {business[0].email}
              </div>
            </div>
            <div className="detailItem">
              <div className="detailTitle">Website</div>
              <div
                className="detailContent"
                style={{ color: "#3141A7", fontWeight: "700" }}
              >
                {business[0].website}
              </div>
            </div>
            <div className="detailItem">
              <div className="detailTitle">Address</div>
              <div
                className="detailContent"
                style={{ color: "#3141A7", fontWeight: "700" }}
              >
                {business[0].address}
                <span style={{ marginLeft: "1rem" }}>
                  <GoLinkExternal
                    onClick={() => handleAddressPress()}
                    style={{ color: "orange" }}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="shadowBar"></div>
          <div className="footer">
            <h2 style={{ color: "#3141A7" }}>Social Media Links</h2>
            <Flex gap="middle" wrap="wrap" style={{ marginLeft: 15 }}>
              <a
                href={createWhatsAppLink(business[0].phone)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  className="socialButton"
                  style={{ backgroundColor: "#32CD32", color: "white" }}
                  shape="circle"
                >
                  <FaWhatsapp size={22} />
                </Button>
              </a>

              <a
                href={ensureHttpPrefix(business[0].instagram)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  className="socialButton"
                  style={{ backgroundColor: "#E71ebe", color: "white" }}
                  shape="circle"
                >
                  <FaInstagram size={22} />
                </Button>
              </a>

              <a
                href={ensureHttpPrefix(business[0].facebook)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  className="socialButton"
                  style={{ backgroundColor: "#1b50cf", color: "white" }}
                  shape="circle"
                >
                  <FaFacebookF size={22} />
                </Button>
              </a>

              <a
                href={ensureHttpPrefix(business[0].linkedin)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  className="socialButton"
                  style={{ backgroundColor: "#1E90FF", color: "white" }}
                  shape="circle"
                >
                  <FaLinkedinIn size={22} />
                </Button>
              </a>

              {/* </Flex> */}
              {/* <br /> */}
              {/* <Flex gap="middle" wrap="wrap"> */}
              <a
                href={ensureHttpPrefix(business[0].twitter)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  className="socialButton"
                  style={{ backgroundColor: "black", color: "white" }}
                  shape="circle"
                >
                  <FaXTwitter size={22} />
                </Button>
              </a>

              <a
                href={ensureHttpPrefix(business[0].youtube)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  className="socialButton"
                  style={{ backgroundColor: "#FF0000", color: "white" }}
                  shape="circle"
                >
                  <FaYoutube size={22} />
                </Button>
              </a>

              <a
                href={ensureHttpPrefix(business[0].tiktok)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  className="socialButton"
                  style={{ backgroundColor: "#000", color: "white" }}
                  shape="circle"
                >
                  <FaTiktok size={22} />
                </Button>
              </a>

              <a
                href={ensureHttpPrefix(business[0].pinterest)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  className="socialButton"
                  style={{ backgroundColor: "#E60023", color: "white" }}
                  shape="circle"
                >
                  <FaPinterestP size={22} />
                </Button>
              </a>
            </Flex>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
          >
            <Button
              className="submitBtn"
              onClick={() => downloadTxtFile(CreateVCard())}
            >
              Save Contact
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <h3>Data Loading</h3>
        </div>
      )}
    </div>
  );
};

export default BusinessCard;
