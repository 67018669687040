/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAIChatRecord = /* GraphQL */ `
  query GetAIChatRecord($id: ID!) {
    getAIChatRecord(id: $id) {
      id
      messages
      userID
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAIChatRecords = /* GraphQL */ `
  query ListAIChatRecords(
    $filter: ModelAIChatRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAIChatRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messages
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const aIChatRecordsByUserID = /* GraphQL */ `
  query AIChatRecordsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAIChatRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    aIChatRecordsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        messages
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotificationModel = /* GraphQL */ `
  query GetNotificationModel($id: ID!) {
    getNotificationModel(id: $id) {
      id
      title
      description
      type
      status
      userID
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotificationModels = /* GraphQL */ `
  query ListNotificationModels(
    $filter: ModelNotificationModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        type
        status
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const notificationModelsByUserID = /* GraphQL */ `
  query NotificationModelsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationModelsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        type
        status
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEzExpertRating = /* GraphQL */ `
  query GetEzExpertRating($id: ID!) {
    getEzExpertRating(id: $id) {
      id
      ezExpertID
      ratingValue
      reviewText
      ratingDate
      businessOwnerID
      businessOwnerName
      businessOnwerProfile
      orderId
      EzExpert {
        id
        isApproved
        AreaOfExpertise
        Description
        Address
        averageRating
        postalCode
        country
        state
        city
        userID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEzExpertRatings = /* GraphQL */ `
  query ListEzExpertRatings(
    $filter: ModelEzExpertRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEzExpertRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ezExpertID
        ratingValue
        reviewText
        ratingDate
        businessOwnerID
        businessOwnerName
        businessOnwerProfile
        orderId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ezExpertRatingsByEzExpertID = /* GraphQL */ `
  query EzExpertRatingsByEzExpertID(
    $ezExpertID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEzExpertRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ezExpertRatingsByEzExpertID(
      ezExpertID: $ezExpertID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ezExpertID
        ratingValue
        reviewText
        ratingDate
        businessOwnerID
        businessOwnerName
        businessOnwerProfile
        orderId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getToDoModel = /* GraphQL */ `
  query GetToDoModel($id: ID!) {
    getToDoModel(id: $id) {
      id
      td_title
      td_description
      td_dueDate
      td_priority
      td_recurrence
      td_status
      td_color
      userID
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listToDoModels = /* GraphQL */ `
  query ListToDoModels(
    $filter: ModelToDoModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listToDoModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        td_title
        td_description
        td_dueDate
        td_priority
        td_recurrence
        td_status
        td_color
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const toDoModelsByUserID = /* GraphQL */ `
  query ToDoModelsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelToDoModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    toDoModelsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        td_title
        td_description
        td_dueDate
        td_priority
        td_recurrence
        td_status
        td_color
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdminModel = /* GraphQL */ `
  query GetAdminModel($id: ID!) {
    getAdminModel(id: $id) {
      id
      ezBizzyFeesPercentage
      invoicePaymentFeesPercentage
      paypalPercentage
      appVersion
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAdminModels = /* GraphQL */ `
  query ListAdminModels(
    $filter: ModelAdminModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ezBizzyFeesPercentage
        invoicePaymentFeesPercentage
        paypalPercentage
        appVersion
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdminNotification = /* GraphQL */ `
  query GetAdminNotification($id: ID!) {
    getAdminNotification(id: $id) {
      id
      type
      title
      description
      typeId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAdminNotifications = /* GraphQL */ `
  query ListAdminNotifications(
    $filter: ModelAdminNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdminNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        title
        description
        typeId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSuperDataLog = /* GraphQL */ `
  query GetSuperDataLog($id: ID!) {
    getSuperDataLog(id: $id) {
      id
      adminSub
      FCMToken
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSuperDataLogs = /* GraphQL */ `
  query ListSuperDataLogs(
    $filter: ModelSuperDataLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuperDataLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        adminSub
        FCMToken
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEzExpertOrderModel = /* GraphQL */ `
  query GetEzExpertOrderModel($id: ID!) {
    getEzExpertOrderModel(id: $id) {
      id
      businessOwnerName
      orderTitle
      status
      ezBizzyFees
      totalAmount
      acceptDate
      duration
      createdDate
      description
      clientSecret
      requirementSubmit
      workCompleted
      requirement
      isofferAccepted
      ezBizzyFeesPercentage
      orderType
      ezExpertID
      businessID
      s3ID
      paymentStatus
      workSubmittedDescription
      ezExpertS3ID
      requirementDescription
      requirementSubmittedDate
      requirementAcceptedDate
      orderExpiryDate
      offerExpiryDate
      workSubmittedDate
      workCompletedDate
      userID
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEzExpertOrderModels = /* GraphQL */ `
  query ListEzExpertOrderModels(
    $filter: ModelEzExpertOrderModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEzExpertOrderModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessOwnerName
        orderTitle
        status
        ezBizzyFees
        totalAmount
        acceptDate
        duration
        createdDate
        description
        clientSecret
        requirementSubmit
        workCompleted
        requirement
        isofferAccepted
        ezBizzyFeesPercentage
        orderType
        ezExpertID
        businessID
        s3ID
        paymentStatus
        workSubmittedDescription
        ezExpertS3ID
        requirementDescription
        requirementSubmittedDate
        requirementAcceptedDate
        orderExpiryDate
        offerExpiryDate
        workSubmittedDate
        workCompletedDate
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ezExpertOrderModelsByUserID = /* GraphQL */ `
  query EzExpertOrderModelsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEzExpertOrderModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ezExpertOrderModelsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        businessOwnerName
        orderTitle
        status
        ezBizzyFees
        totalAmount
        acceptDate
        duration
        createdDate
        description
        clientSecret
        requirementSubmit
        workCompleted
        requirement
        isofferAccepted
        ezBizzyFeesPercentage
        orderType
        ezExpertID
        businessID
        s3ID
        paymentStatus
        workSubmittedDescription
        ezExpertS3ID
        requirementDescription
        requirementSubmittedDate
        requirementAcceptedDate
        orderExpiryDate
        offerExpiryDate
        workSubmittedDate
        workCompletedDate
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEzExpertOrderDisputeModel = /* GraphQL */ `
  query GetEzExpertOrderDisputeModel($id: ID!) {
    getEzExpertOrderDisputeModel(id: $id) {
      id
      orderId
      disputeTitle
      description
      reason
      businessOwnerName
      ezExpertName
      status
      createdDate
      disputereference
      note
      dis_profile
      resolveDate
      ezExpertID
      businessID
      userID
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEzExpertOrderDisputeModels = /* GraphQL */ `
  query ListEzExpertOrderDisputeModels(
    $filter: ModelEzExpertOrderDisputeModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEzExpertOrderDisputeModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        disputeTitle
        description
        reason
        businessOwnerName
        ezExpertName
        status
        createdDate
        disputereference
        note
        dis_profile
        resolveDate
        ezExpertID
        businessID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ezExpertOrderDisputeModelsByUserID = /* GraphQL */ `
  query EzExpertOrderDisputeModelsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEzExpertOrderDisputeModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ezExpertOrderDisputeModelsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        disputeTitle
        description
        reason
        businessOwnerName
        ezExpertName
        status
        createdDate
        disputereference
        note
        dis_profile
        resolveDate
        ezExpertID
        businessID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEzExpertPortfolioModel = /* GraphQL */ `
  query GetEzExpertPortfolioModel($id: ID!) {
    getEzExpertPortfolioModel(id: $id) {
      id
      title
      description
      link
      skills
      userID
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEzExpertPortfolioModels = /* GraphQL */ `
  query ListEzExpertPortfolioModels(
    $filter: ModelEzExpertPortfolioModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEzExpertPortfolioModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        link
        skills
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ezExpertPortfolioModelsByUserID = /* GraphQL */ `
  query EzExpertPortfolioModelsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEzExpertPortfolioModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ezExpertPortfolioModelsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        link
        skills
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBlogModel = /* GraphQL */ `
  query GetBlogModel($id: ID!) {
    getBlogModel(id: $id) {
      id
      title
      description
      isPublish
      displayToEzExpert
      displayToBusiness
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBlogModels = /* GraphQL */ `
  query ListBlogModels(
    $filter: ModelBlogModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        isPublish
        displayToEzExpert
        displayToBusiness
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDataplanModel = /* GraphQL */ `
  query GetDataplanModel($id: ID!) {
    getDataplanModel(id: $id) {
      id
      title
      price
      period
      perks
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDataplanModels = /* GraphQL */ `
  query ListDataplanModels(
    $filter: ModelDataplanModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataplanModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        price
        period
        perks
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSubscriberModel = /* GraphQL */ `
  query GetSubscriberModel($id: ID!) {
    getSubscriberModel(id: $id) {
      id
      BusinessName
      email
      amount
      status
      startDate
      endDate
      userID
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSubscriberModels = /* GraphQL */ `
  query ListSubscriberModels(
    $filter: ModelSubscriberModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriberModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        BusinessName
        email
        amount
        status
        startDate
        endDate
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const subscriberModelsByUserID = /* GraphQL */ `
  query SubscriberModelsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriberModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscriberModelsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        BusinessName
        email
        amount
        status
        startDate
        endDate
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDisputeReasonsModel = /* GraphQL */ `
  query GetDisputeReasonsModel($id: ID!) {
    getDisputeReasonsModel(id: $id) {
      id
      isSelected
      reason
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDisputeReasonsModels = /* GraphQL */ `
  query ListDisputeReasonsModels(
    $filter: ModelDisputeReasonsModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDisputeReasonsModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isSelected
        reason
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWithdrawalModel = /* GraphQL */ `
  query GetWithdrawalModel($id: ID!) {
    getWithdrawalModel(id: $id) {
      id
      amount
      requestDate
      method
      accountNumber
      cardNumber
      failureMessage
      isBusinessOwner
      transactionId
      status
      notes
      AdminReferenceNum
      paypalPercentage
      dateTimeOfAdminPayment
      userID
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWithdrawalModels = /* GraphQL */ `
  query ListWithdrawalModels(
    $filter: ModelWithdrawalModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWithdrawalModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        requestDate
        method
        accountNumber
        cardNumber
        failureMessage
        isBusinessOwner
        transactionId
        status
        notes
        AdminReferenceNum
        paypalPercentage
        dateTimeOfAdminPayment
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const withdrawalModelsByUserID = /* GraphQL */ `
  query WithdrawalModelsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWithdrawalModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    withdrawalModelsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        requestDate
        method
        accountNumber
        cardNumber
        failureMessage
        isBusinessOwner
        transactionId
        status
        notes
        AdminReferenceNum
        paypalPercentage
        dateTimeOfAdminPayment
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPaymentModel = /* GraphQL */ `
  query GetPaymentModel($id: ID!) {
    getPaymentModel(id: $id) {
      id
      amount
      paymentDate
      requestDate
      method
      accountNumber
      cardNumber
      isBusinessOwner
      transactionId
      status
      failureMessage
      userID
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPaymentModels = /* GraphQL */ `
  query ListPaymentModels(
    $filter: ModelPaymentModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amount
        paymentDate
        requestDate
        method
        accountNumber
        cardNumber
        isBusinessOwner
        transactionId
        status
        failureMessage
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const paymentModelsByUserID = /* GraphQL */ `
  query PaymentModelsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentModelsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        paymentDate
        requestDate
        method
        accountNumber
        cardNumber
        isBusinessOwner
        transactionId
        status
        failureMessage
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEzExpertService = /* GraphQL */ `
  query GetEzExpertService($id: ID!) {
    getEzExpertService(id: $id) {
      id
      isApproved
      title
      price
      duration
      category
      subCategory
      description
      requirement
      skills
      images
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEzExpertServices = /* GraphQL */ `
  query ListEzExpertServices(
    $filter: ModelEzExpertServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEzExpertServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isApproved
        title
        price
        duration
        category
        subCategory
        description
        requirement
        skills
        images
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ezExpertServicesByUserID = /* GraphQL */ `
  query EzExpertServicesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEzExpertServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ezExpertServicesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isApproved
        title
        price
        duration
        category
        subCategory
        description
        requirement
        skills
        images
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSendBirdChat = /* GraphQL */ `
  query GetSendBirdChat($id: ID!) {
    getSendBirdChat(id: $id) {
      id
      userName
      user2_Name
      channelURL
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSendBirdChats = /* GraphQL */ `
  query ListSendBirdChats(
    $filter: ModelSendBirdChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSendBirdChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userName
        user2_Name
        channelURL
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sendBirdChatsByUserID = /* GraphQL */ `
  query SendBirdChatsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSendBirdChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sendBirdChatsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userName
        user2_Name
        channelURL
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEzExpertLicense = /* GraphQL */ `
  query GetEzExpertLicense($id: ID!) {
    getEzExpertLicense(id: $id) {
      id
      title
      type
      startDate
      isApproved
      endDate
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEzExpertLicenses = /* GraphQL */ `
  query ListEzExpertLicenses(
    $filter: ModelEzExpertLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEzExpertLicenses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        type
        startDate
        isApproved
        endDate
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ezExpertLicensesByUserID = /* GraphQL */ `
  query EzExpertLicensesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEzExpertLicenseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ezExpertLicensesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        type
        startDate
        isApproved
        endDate
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEzExpertModel = /* GraphQL */ `
  query GetEzExpertModel($id: ID!) {
    getEzExpertModel(id: $id) {
      id
      isApproved
      AreaOfExpertise
      Description
      Address
      Ratings {
        nextToken
        __typename
      }
      averageRating
      postalCode
      country
      state
      city
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEzExpertModels = /* GraphQL */ `
  query ListEzExpertModels(
    $filter: ModelEzExpertModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEzExpertModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isApproved
        AreaOfExpertise
        Description
        Address
        averageRating
        postalCode
        country
        state
        city
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ezExpertModelsByUserID = /* GraphQL */ `
  query EzExpertModelsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEzExpertModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ezExpertModelsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isApproved
        AreaOfExpertise
        Description
        Address
        averageRating
        postalCode
        country
        state
        city
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPrepaidCard = /* GraphQL */ `
  query GetPrepaidCard($id: ID!) {
    getPrepaidCard(id: $id) {
      id
      firstName
      lastName
      dateOFBirth
      email
      address
      country
      cardNumber
      requestApproved
      approvalDate
      expiryDate
      cvvNumber
      userID
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPrepaidCards = /* GraphQL */ `
  query ListPrepaidCards(
    $filter: ModelPrepaidCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPrepaidCards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        dateOFBirth
        email
        address
        country
        cardNumber
        requestApproved
        approvalDate
        expiryDate
        cvvNumber
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const prepaidCardsByUserID = /* GraphQL */ `
  query PrepaidCardsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPrepaidCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    prepaidCardsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        dateOFBirth
        email
        address
        country
        cardNumber
        requestApproved
        approvalDate
        expiryDate
        cvvNumber
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBankAccountDetails = /* GraphQL */ `
  query GetBankAccountDetails($id: ID!) {
    getBankAccountDetails(id: $id) {
      id
      country
      accountType
      bankName
      bankAddress
      bankRoutingNumber
      bankAccountNumber
      name
      userID
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBankAccountDetails = /* GraphQL */ `
  query ListBankAccountDetails(
    $filter: ModelBankAccountDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBankAccountDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        country
        accountType
        bankName
        bankAddress
        bankRoutingNumber
        bankAccountNumber
        name
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const bankAccountDetailsByUserID = /* GraphQL */ `
  query BankAccountDetailsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBankAccountDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bankAccountDetailsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        country
        accountType
        bankName
        bankAddress
        bankRoutingNumber
        bankAccountNumber
        name
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPaypalDetails = /* GraphQL */ `
  query GetPaypalDetails($id: ID!) {
    getPaypalDetails(id: $id) {
      id
      name
      paypalUsername
      email
      mobileNo
      userID
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPaypalDetails = /* GraphQL */ `
  query ListPaypalDetails(
    $filter: ModelPaypalDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaypalDetails(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        paypalUsername
        email
        mobileNo
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const paypalDetailsByUserID = /* GraphQL */ `
  query PaypalDetailsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPaypalDetailsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paypalDetailsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        paypalUsername
        email
        mobileNo
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEzExpertExperience = /* GraphQL */ `
  query GetEzExpertExperience($id: ID!) {
    getEzExpertExperience(id: $id) {
      id
      jobtitle
      company
      specialization
      userID
      skills
      isApproved
      startDate
      endDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEzExpertExperiences = /* GraphQL */ `
  query ListEzExpertExperiences(
    $filter: ModelEzExpertExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEzExpertExperiences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobtitle
        company
        specialization
        userID
        skills
        isApproved
        startDate
        endDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ezExpertExperiencesByUserID = /* GraphQL */ `
  query EzExpertExperiencesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEzExpertExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ezExpertExperiencesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        jobtitle
        company
        specialization
        userID
        skills
        isApproved
        startDate
        endDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getItemTax = /* GraphQL */ `
  query GetItemTax($id: ID!) {
    getItemTax(id: $id) {
      id
      taxName
      taxRate
      invoiceitemID
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listItemTaxes = /* GraphQL */ `
  query ListItemTaxes(
    $filter: ModelItemTaxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItemTaxes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taxName
        taxRate
        invoiceitemID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTaxesByInvoiceitemID = /* GraphQL */ `
  query ItemTaxesByInvoiceitemID(
    $invoiceitemID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTaxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTaxesByInvoiceitemID(
      invoiceitemID: $invoiceitemID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taxName
        taxRate
        invoiceitemID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const itemTaxesByUserID = /* GraphQL */ `
  query ItemTaxesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelItemTaxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    itemTaxesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taxName
        taxRate
        invoiceitemID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEzExpertCerificate = /* GraphQL */ `
  query GetEzExpertCerificate($id: ID!) {
    getEzExpertCerificate(id: $id) {
      id
      title
      source
      startDate
      expiryDate
      isApproved
      userID
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEzExpertCerificates = /* GraphQL */ `
  query ListEzExpertCerificates(
    $filter: ModelEzExpertCerificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEzExpertCerificates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        source
        startDate
        expiryDate
        isApproved
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ezExpertCerificatesByUserID = /* GraphQL */ `
  query EzExpertCerificatesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEzExpertCerificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ezExpertCerificatesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        source
        startDate
        expiryDate
        isApproved
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEzExpertEducation = /* GraphQL */ `
  query GetEzExpertEducation($id: ID!) {
    getEzExpertEducation(id: $id) {
      id
      courseName
      educationInstitute
      major
      awards
      isApproved
      userID
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEzExpertEducations = /* GraphQL */ `
  query ListEzExpertEducations(
    $filter: ModelEzExpertEducationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEzExpertEducations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseName
        educationInstitute
        major
        awards
        isApproved
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ezExpertEducationsByUserID = /* GraphQL */ `
  query EzExpertEducationsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEzExpertEducationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ezExpertEducationsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        courseName
        educationInstitute
        major
        awards
        isApproved
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getStripePaymentModel = /* GraphQL */ `
  query GetStripePaymentModel($id: ID!) {
    getStripePaymentModel(id: $id) {
      id
      paymentlinkid
      invoiceID
      totalAmount
      userID
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listStripePaymentModels = /* GraphQL */ `
  query ListStripePaymentModels(
    $filter: ModelStripePaymentModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStripePaymentModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paymentlinkid
        invoiceID
        totalAmount
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const stripePaymentModelsByUserID = /* GraphQL */ `
  query StripePaymentModelsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelStripePaymentModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    stripePaymentModelsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        paymentlinkid
        invoiceID
        totalAmount
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoicePayment = /* GraphQL */ `
  query GetInvoicePayment($id: ID!) {
    getInvoicePayment(id: $id) {
      id
      date
      paymentMethod
      account
      amount
      paymentProof
      ezBizzyFeesPercentage
      invoiceID
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInvoicePayments = /* GraphQL */ `
  query ListInvoicePayments(
    $filter: ModelInvoicePaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoicePayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        paymentMethod
        account
        amount
        paymentProof
        ezBizzyFeesPercentage
        invoiceID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const invoicePaymentsByInvoiceID = /* GraphQL */ `
  query InvoicePaymentsByInvoiceID(
    $invoiceID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoicePaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicePaymentsByInvoiceID(
      invoiceID: $invoiceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        paymentMethod
        account
        amount
        paymentProof
        ezBizzyFeesPercentage
        invoiceID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const invoicePaymentsByUserID = /* GraphQL */ `
  query InvoicePaymentsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoicePaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicePaymentsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        paymentMethod
        account
        amount
        paymentProof
        ezBizzyFeesPercentage
        invoiceID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWithdrawalReceipt = /* GraphQL */ `
  query GetWithdrawalReceipt($id: ID!) {
    getWithdrawalReceipt(id: $id) {
      id
      requestDate
      amount
      status
      receivalMethod
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWithdrawalReceipts = /* GraphQL */ `
  query ListWithdrawalReceipts(
    $filter: ModelWithdrawalReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWithdrawalReceipts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        requestDate
        amount
        status
        receivalMethod
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const withdrawalReceiptsByUserID = /* GraphQL */ `
  query WithdrawalReceiptsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelWithdrawalReceiptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    withdrawalReceiptsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        requestDate
        amount
        status
        receivalMethod
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBusinessCardModel = /* GraphQL */ `
  query GetBusinessCardModel($id: ID!) {
    getBusinessCardModel(id: $id) {
      id
      phone
      email
      website
      address
      facebook
      youtube
      instagram
      whatsapp
      company_name
      name
      linkedin
      twitter
      pinterest
      tiktok
      userID
      postalCode
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      createdAt
      updatedAt
      businessCardModelUserId
      __typename
    }
  }
`;
export const listBusinessCardModels = /* GraphQL */ `
  query ListBusinessCardModels(
    $filter: ModelBusinessCardModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessCardModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        phone
        email
        website
        address
        facebook
        youtube
        instagram
        whatsapp
        company_name
        name
        linkedin
        twitter
        pinterest
        tiktok
        userID
        postalCode
        createdAt
        updatedAt
        businessCardModelUserId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoiceItem = /* GraphQL */ `
  query GetInvoiceItem($id: ID!) {
    getInvoiceItem(id: $id) {
      id
      title
      description
      quantity
      price
      invoiceID
      userID
      ItemTaxes {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInvoiceItems = /* GraphQL */ `
  query ListInvoiceItems(
    $filter: ModelInvoiceItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoiceItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        quantity
        price
        invoiceID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const invoiceItemsByInvoiceID = /* GraphQL */ `
  query InvoiceItemsByInvoiceID(
    $invoiceID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceItemsByInvoiceID(
      invoiceID: $invoiceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        quantity
        price
        invoiceID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const invoiceItemsByUserID = /* GraphQL */ `
  query InvoiceItemsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceItemsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        quantity
        price
        invoiceID
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      title
      invoiceNumber
      orderNumber
      summary
      client
      issueDate
      paymentDate
      currency
      items
      subTotal
      total
      InvoiceItems {
        nextToken
        __typename
      }
      status
      paymentMethod
      tax
      userID
      InvoicePayments {
        nextToken
        __typename
      }
      issueDateString
      paymentDateString
      businessName
      ezBizzyFees
      paymentMode
      failureMessage
      cardNumber
      AccountNumber
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        invoiceNumber
        orderNumber
        summary
        client
        issueDate
        paymentDate
        currency
        items
        subTotal
        total
        status
        paymentMethod
        tax
        userID
        issueDateString
        paymentDateString
        businessName
        ezBizzyFees
        paymentMode
        failureMessage
        cardNumber
        AccountNumber
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const invoicesByUserID = /* GraphQL */ `
  query InvoicesByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        invoiceNumber
        orderNumber
        summary
        client
        issueDate
        paymentDate
        currency
        items
        subTotal
        total
        status
        paymentMethod
        tax
        userID
        issueDateString
        paymentDateString
        businessName
        ezBizzyFees
        paymentMode
        failureMessage
        cardNumber
        AccountNumber
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      event_title
      isVirtual
      event_description
      event_dateTime_start
      event_dateTime_end
      event_invite_link
      event_phy_location
      event_attendees
      userID
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      SbirdVideos {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        event_title
        isVirtual
        event_description
        event_dateTime_start
        event_dateTime_end
        event_invite_link
        event_phy_location
        event_attendees
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const eventsByUserID = /* GraphQL */ `
  query EventsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        event_title
        isVirtual
        event_description
        event_dateTime_start
        event_dateTime_end
        event_invite_link
        event_phy_location
        event_attendees
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSbirdVideo = /* GraphQL */ `
  query GetSbirdVideo($id: ID!) {
    getSbirdVideo(id: $id) {
      id
      room_title
      room_id
      room_time_stamp
      eventID
      Event {
        id
        event_title
        isVirtual
        event_description
        event_dateTime_start
        event_dateTime_end
        event_invite_link
        event_phy_location
        event_attendees
        userID
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSbirdVideos = /* GraphQL */ `
  query ListSbirdVideos(
    $filter: ModelSbirdVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSbirdVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        room_title
        room_id
        room_time_stamp
        eventID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const sbirdVideosByEventID = /* GraphQL */ `
  query SbirdVideosByEventID(
    $eventID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSbirdVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sbirdVideosByEventID(
      eventID: $eventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        room_title
        room_id
        room_time_stamp
        eventID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRequestInvoicePayment = /* GraphQL */ `
  query GetRequestInvoicePayment($id: ID!) {
    getRequestInvoicePayment(id: $id) {
      id
      isApproved
      userID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRequestInvoicePayments = /* GraphQL */ `
  query ListRequestInvoicePayments(
    $filter: ModelRequestInvoicePaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRequestInvoicePayments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isApproved
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const requestInvoicePaymentsByUserID = /* GraphQL */ `
  query RequestInvoicePaymentsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelrequestInvoicePaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    requestInvoicePaymentsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isApproved
        userID
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getHRModel = /* GraphQL */ `
  query GetHRModel($id: ID!) {
    getHRModel(id: $id) {
      id
      emp_name
      emp_email
      emp_phone
      emp_dob
      emp_nationality
      emp_address
      emp_ssn
      emergency_contact
      designation
      department
      date_joining
      salary_period
      emp_status
      isDeleted
      country
      state
      city
      postalCode
      userID
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      emp_profile
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listHRModels = /* GraphQL */ `
  query ListHRModels(
    $filter: ModelHRModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHRModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        emp_name
        emp_email
        emp_phone
        emp_dob
        emp_nationality
        emp_address
        emp_ssn
        emergency_contact
        designation
        department
        date_joining
        salary_period
        emp_status
        isDeleted
        country
        state
        city
        postalCode
        userID
        emp_profile
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const hRModelsByUserID = /* GraphQL */ `
  query HRModelsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelHRModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    hRModelsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        emp_name
        emp_email
        emp_phone
        emp_dob
        emp_nationality
        emp_address
        emp_ssn
        emergency_contact
        designation
        department
        date_joining
        salary_period
        emp_status
        isDeleted
        country
        state
        city
        postalCode
        userID
        emp_profile
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClientModel = /* GraphQL */ `
  query GetClientModel($id: ID!) {
    getClientModel(id: $id) {
      id
      client_name
      client_email
      client_phone
      client_business
      address
      country
      state
      city
      website
      whatsapp
      facebook
      youtube
      instagram
      ref_source
      ref_source_name
      ref_source_designation
      isDeleted
      postalCode
      userID
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      businessmodelID
      BusinessModel {
        id
        BusinessName
        RegisteredAgent
        BusinessAddress
        businessCountry
        businessState
        businessCity
        MailingAddress
        mailingCountry
        mailingState
        mailingCity
        TaxId
        TotalClients
        TotalEmployees
        ContactPersons
        BusinessType
        userID
        profile_picture
        storageKey
        hasBusinessCard
        postalCode
        email
        OngoingProjects
        TotalProjects
        createdAt
        updatedAt
        businessModelUserId
        __typename
      }
      client_profile
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClientModels = /* GraphQL */ `
  query ListClientModels(
    $filter: ModelClientModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        client_name
        client_email
        client_phone
        client_business
        address
        country
        state
        city
        website
        whatsapp
        facebook
        youtube
        instagram
        ref_source
        ref_source_name
        ref_source_designation
        isDeleted
        postalCode
        userID
        businessmodelID
        client_profile
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const clientModelsByUserID = /* GraphQL */ `
  query ClientModelsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClientModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientModelsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        client_name
        client_email
        client_phone
        client_business
        address
        country
        state
        city
        website
        whatsapp
        facebook
        youtube
        instagram
        ref_source
        ref_source_name
        ref_source_designation
        isDeleted
        postalCode
        userID
        businessmodelID
        client_profile
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const clientModelsByBusinessmodelID = /* GraphQL */ `
  query ClientModelsByBusinessmodelID(
    $businessmodelID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClientModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientModelsByBusinessmodelID(
      businessmodelID: $businessmodelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        client_name
        client_email
        client_phone
        client_business
        address
        country
        state
        city
        website
        whatsapp
        facebook
        youtube
        instagram
        ref_source
        ref_source_name
        ref_source_designation
        isDeleted
        postalCode
        userID
        businessmodelID
        client_profile
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBusinessModel = /* GraphQL */ `
  query GetBusinessModel($id: ID!) {
    getBusinessModel(id: $id) {
      id
      BusinessName
      RegisteredAgent
      BusinessAddress
      businessCountry
      businessState
      businessCity
      MailingAddress
      mailingCountry
      mailingState
      mailingCity
      TaxId
      TotalClients
      TotalEmployees
      ContactPersons
      BusinessType
      User {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      userID
      profile_picture
      storageKey
      hasBusinessCard
      postalCode
      ClientModels {
        nextToken
        __typename
      }
      email
      OngoingProjects
      TotalProjects
      createdAt
      updatedAt
      businessModelUserId
      __typename
    }
  }
`;
export const listBusinessModels = /* GraphQL */ `
  query ListBusinessModels(
    $filter: ModelBusinessModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        BusinessName
        RegisteredAgent
        BusinessAddress
        businessCountry
        businessState
        businessCity
        MailingAddress
        mailingCountry
        mailingState
        mailingCity
        TaxId
        TotalClients
        TotalEmployees
        ContactPersons
        BusinessType
        userID
        profile_picture
        storageKey
        hasBusinessCard
        postalCode
        email
        OngoingProjects
        TotalProjects
        createdAt
        updatedAt
        businessModelUserId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      password
      phone_number
      full_name
      sendbird_video_token
      username
      cogID
      isEZExpertApproved
      isBusinessProfileFilled
      socialLogin
      isOTPCompleted
      isFaceIDEnabled
      isTouchIDEnabled
      preferredMethod
      isFeaturedEzExpert
      isProfilePicUploaded
      isInvoiceOnlinePaymentApproved
      expertOnBoardingStatus
      FCMToken
      APNToken
      adminStatus
      BusinessModel {
        id
        BusinessName
        RegisteredAgent
        BusinessAddress
        businessCountry
        businessState
        businessCity
        MailingAddress
        mailingCountry
        mailingState
        mailingCity
        TaxId
        TotalClients
        TotalEmployees
        ContactPersons
        BusinessType
        userID
        profile_picture
        storageKey
        hasBusinessCard
        postalCode
        email
        OngoingProjects
        TotalProjects
        createdAt
        updatedAt
        businessModelUserId
        __typename
      }
      ClientModels {
        nextToken
        __typename
      }
      HRModels {
        nextToken
        __typename
      }
      Events {
        nextToken
        __typename
      }
      BusinessCardModel {
        id
        phone
        email
        website
        address
        facebook
        youtube
        instagram
        whatsapp
        company_name
        name
        linkedin
        twitter
        pinterest
        tiktok
        userID
        postalCode
        createdAt
        updatedAt
        businessCardModelUserId
        __typename
      }
      Invoices {
        nextToken
        __typename
      }
      WithdrawalReceipts {
        nextToken
        __typename
      }
      InvoiceItems {
        nextToken
        __typename
      }
      InvoicePayments {
        nextToken
        __typename
      }
      isBusinessOwner
      EzExpertEducations {
        nextToken
        __typename
      }
      EzExpertCerificates {
        nextToken
        __typename
      }
      ItemTaxes {
        nextToken
        __typename
      }
      EzExpertExperiences {
        nextToken
        __typename
      }
      BankAccountDetails {
        nextToken
        __typename
      }
      PrepaidCards {
        nextToken
        __typename
      }
      PaypalDetails {
        nextToken
        __typename
      }
      EzExpertModel {
        nextToken
        __typename
      }
      EzExpertLicenses {
        nextToken
        __typename
      }
      SendBirdChats {
        nextToken
        __typename
      }
      PaymentModels {
        nextToken
        __typename
      }
      WithdrawalModels {
        nextToken
        __typename
      }
      SubscriberModels {
        nextToken
        __typename
      }
      EzExpertPortfolioModels {
        nextToken
        __typename
      }
      EzExpertOrderModels {
        nextToken
        __typename
      }
      requestInvoicePayments {
        nextToken
        __typename
      }
      EzExpertOrderDisputeModels {
        nextToken
        __typename
      }
      ToDoModel {
        nextToken
        __typename
      }
      AIChatRecord {
        nextToken
        __typename
      }
      NotificationModel {
        nextToken
        __typename
      }
      StripePaymentModel {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      userBusinessModelId
      userBusinessCardModelId
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        password
        phone_number
        full_name
        sendbird_video_token
        username
        cogID
        isEZExpertApproved
        isBusinessProfileFilled
        socialLogin
        isOTPCompleted
        isFaceIDEnabled
        isTouchIDEnabled
        preferredMethod
        isFeaturedEzExpert
        isProfilePicUploaded
        isInvoiceOnlinePaymentApproved
        expertOnBoardingStatus
        FCMToken
        APNToken
        adminStatus
        isBusinessOwner
        createdAt
        updatedAt
        userBusinessModelId
        userBusinessCardModelId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
