/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "10fd1928e9544b82ac587c6f33c5dacc",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "10fd1928e9544b82ac587c6f33c5dacc",
            "region": "us-east-1"
        }
    },
    "Notifications": {
        "Push": {
            "AWSPinpoint": {
                "appId": "10fd1928e9544b82ac587c6f33c5dacc",
                "region": "us-east-1"
            }
        }
    },
    "aws_appsync_graphqlEndpoint": "https://d6jwwbvymvhftdckzayugkjfiu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-wd2sm3nr3neijlzwvjmzm4hxey",
    "aws_cognito_identity_pool_id": "us-east-1:020431dc-3ff5-428d-b5ba-f1893ac8975b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_VoU6cG1um",
    "aws_user_pools_web_client_id": "5apnh7clhucnnkv53is0bnbk8i",
    "oauth": {
        "domain": "ezbizzydevb73d708f-b73d708f-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "myapp://",
        "redirectSignOut": "myapp://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "ezbizzy-filestorage12345836-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
